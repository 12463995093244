import { useState, useEffect } from "react";
import {
  Flex,
  Heading,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  Box,
  Image,
  FormControl,
} from "@chakra-ui/react";
import { FaUserAlt } from "react-icons/fa";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { REQUEST_OTP } from "../gql/auth.queries";
import { get, set } from "lscache";
import { Footer } from "../components/shared/Footer";
import DocuTrailLogo from '../assets/DocuTrailLogo.png'
import TadrosAccounting from '../assets/TadrosAccounting.png'
import useToastSet from "../hooks/useToastSet";
import { useMediaQuery } from "react-responsive";

const CFaUserAlt = chakra(FaUserAlt);

const Landing = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [email, setEmail] = useState("");
  const [sendingOTP, setSendingOTP] = useState(false);
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });

  const {
    setSuccessMessage,
    setErrorMessage,
  } = useToastSet();

  const [requestOTP] = useMutation(REQUEST_OTP, {
    onError: (error) => {
      console.error("An unexpected error happened:", error);
      setErrorMessage(error.message);
      setEmail("");
      setSendingOTP(false);
    },
    onCompleted: () => {
      setSendingOTP(false);
      setSuccessMessage(`We've sent your OTP to ${email}`);
      set("user", { otpSent: true, authenticated: false });
      navigate("/login");
    },
    variables: { input: { email } },
  });

  useEffect(() => {
    const user = get("user");
    if (user && user.authenticated) {
     navigate("/dashboard");
      if (user.administrator) {
        navigate("/administration");
      }
    }
    if (user && user.otpSent) {
      navigate("/login");
    }
  });

  return (
    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      backgroundColor="gray.100"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        flexDir="column"
        mb="2"
        justifyContent="center"
        alignItems="center"
        spacing={8}
      >
        {
          searchParams.get('org') === 'tadros-accounting' ? (
            <Stack
              direction={isMobile ? 'column' : 'row'}
            >
                <Image width="250px" src={TadrosAccounting} ></Image>
                <div
                  style={{
                    border: "1px solid #dddddd",
                    height: isMobile ? "0" : "100%",
                    width: isMobile ? "100%" : "0",
                    margin: isMobile ? "20px 0 10px 0" : "0 30px"
                  }}
                ></div>
                <Image width="250px" src={DocuTrailLogo} ></Image>
            </Stack>
          ) : (
            <Image width="300px" src={DocuTrailLogo} ></Image>
          )
        }

        <Heading color="blue.400" size="lg">Client Portal</Heading>
        <br />
        <Box minW={{ base: "90%", md: "468px" }}>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              setSendingOTP(true);
              requestOTP();
            }}
          >
            <Stack
              spacing={4}
              p="1rem"
              backgroundColor="whiteAlpha.900"
              boxShadow="md"
              borderRadius="5px"
            >
              <FormControl>
                <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    <CFaUserAlt color="gray.300" />
                  </InputLeftElement>
                  <Input
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    type="email"
                    placeholder="Email"
                  />
                </InputGroup>
              </FormControl>
              <Button
                disabled={sendingOTP}
                borderRadius={0}
                variant="solid"
                colorScheme="blue"
                width="full"
                type="submit"
              >
                Request One-Time Password (OTP)
              </Button>
            </Stack>
          </form>
        </Box>
      </Stack>
      <Footer 
        bottom="0"
        position={{ md: "fixed", base: "absolute"}}
      />
    </Flex>
  );
};

export default Landing;
