import gql from "graphql-tag";

export const REQUEST_OTP = gql`
  mutation RequestOTP($input: RequestOTPInput!) {
    requestOTP(input: $input) {
      user {
        admin
        owner
      }
      authorized
    }
  }
`;

export const LOGIN = gql`
  mutation Login($input: AuthenticateInput!) {
    authenticate(input: $input) {
      user {
        id
        name
        email
        admin
        owner
      }
    }
  }
`;

export const VALIDATE_SESSION = gql`
  query ValidateSession {
    session: validateSession {
      authorized
      user {
        admin
        owner
      }
    }
  }
`;

export const LOGOUT = gql`
  mutation Logout {
    logout {
      user {
        id
      }
    }
  }
`;
