import React from 'react';
import './styles/globals.css';
import theme from './theme';
import {
  ChakraProvider,
  ColorModeScript,
} from "@chakra-ui/react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from "@apollo/client";
import Landing from './pages/Landing';
import Login from './pages/Login';

const AdminDashboard = React.lazy(() => import('./pages/AdminDashboard'));
const ClientDashboard = React.lazy(() => import('./pages/ClientDashboard'));
const OwnerDashboard = React.lazy(() => import('./pages/OwnerDashboard'));

const link = createHttpLink({
  uri: process.env.REACT_APP_SERVER_URL,
  credentials: process.env.REACT_APP_CORS_CREDENTIALS,
});

export const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
  credentials: process.env.REACT_APP_CORS_CREDENTIALS,
});

function App() {
  return (
  <Router>
    <ChakraProvider>
      <ApolloProvider client={client}>
        <ColorModeScript initialColorMode={theme.config.initialColorMode} />
        <Routes>
          <Route path="/" element={<Landing />} />

          <Route path="/login" element={<Login />} />

          <Route 
            path='/dashboard/*' 
            element={
              <React.Suspense fallback={<>...</>}>
                <ClientDashboard/>
              </React.Suspense>
            }
          />

          <Route 
            path='/administration/*' 
            element={
              <React.Suspense fallback={<>...</>}>
                <AdminDashboard/>
              </React.Suspense>
            }
          />

          <Route 
            path='/owner/*' 
            element={
              <React.Suspense fallback={<>...</>}>
                <OwnerDashboard/>
              </React.Suspense>
            }
          />

          <Route path="*" element={<Landing />} />
        </Routes>
      </ApolloProvider>
    </ChakraProvider>
  </Router>
  )
}

export default App;
