const CONSTANTS = {
  TIME: {
    FIVE_MINUTES_IN_SECONDS: 60 * 5,
    TWO_WEEKS_IN_SECONDS: 604800 * 2,
  },
  MESSAGES: {},
  TABLES: {
    DESKTOP_FILE_COLUMNS: [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Category",
        accessor: "category",
      },
      {
        Header: "Date",
        accessor: "date",
      },
      {
        Header: "Actions",
        accessor: "actions",
      },
    ],
    MOBILE_FILE_COLUMNS: [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Actions",
        accessor: "actions",
      },
    ],
    CATEGORY_ROOT_DESKTOP_COLUMNS: [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Category",
        accessor: "category",
      },
      {
        Header: "Date",
        accessor: "date",
      },
      {
        Header: "Actions",
        accessor: "actions",
      },
    ],
    CATEGORY_ROOT_MOBILE_COLUMNS: [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Actions",
        accessor: "actions",
      },
    ],
  },
};

export default CONSTANTS;
