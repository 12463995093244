import { gql } from "@apollo/client";
import { Organization } from "./schema";
export interface IOrganizationData {
  organization: Organization;
}

export const GET_ORGANIZATION_BASICS = gql`
  query getOrganization {
    organization: getOrganzation {
      id
      name
      alias
      logoUrl
    }
  }
`;

export const GET_ORGANIZATION = gql`
  query getOrganization {
    organization: getOrganzation {
      id
      name
      email
      alias
      logoUrl
      userCount
      adminCount
      distributionCount
      fileCount
    }
  }
`;

export const UPDATE_ORGANIZATION = gql`
  mutation updateOrganization($input: UpdateOrganizationInput!) {
    organization: updateOrganization(input: $input) {
      id
      name
      email
      alias
      logoUrl
      userCount
      adminCount
      distributionCount
      fileCount
    }
  }
`;
