import { useState, useEffect } from "react";
import {
  Flex,
  Heading,
  InputGroup,
  Stack,
  Box,
  Avatar,
  FormControl,
  PinInput,
  PinInputField,
  HStack,
  Button,
  Text,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { LOGIN, LOGOUT } from "../gql/auth.queries";
import { get, remove, set } from "lscache";
import { Footer } from "../components/shared/Footer";
import { GET_ORGANIZATION_BASICS } from "../gql/organization.queries";
import useToastSet from "../hooks/useToastSet";

const Login = () => {
  const navigate = useNavigate();
  const user = get("user");
  const [validatingOTP, setValidatingOTP] = useState(false);

  const {
    setErrorMessage,
  } = useToastSet();

  const { loading, error, data } = useQuery(GET_ORGANIZATION_BASICS);

  useEffect(() => {
  if (error) {
    setErrorMessage(error.message);
  }

  if (!loading && data) {
    const { organization } = data;
    set("organization", {
      ...organization
    })
  };
  }, [loading, error, data, setErrorMessage])



  const [login] = useMutation(LOGIN, {
    onError: (error) => {
      setErrorMessage(error.message);
      setValidatingOTP(false);
    },
    onCompleted: ({ authenticate: { user } }) => {
      const currentUser = get("user");
      set("user", {
        ...currentUser,
        ...user,
        authenticated: true,
        administrator: user.admin,
      });
      setValidatingOTP(false);
    },
  });

  const [logout] = useMutation(LOGOUT, {
    onError: (error) => {
      setErrorMessage(error.message);
      navigate("/");
      remove("user");
      remove("organization");
    },
    onCompleted: () => {
      navigate("/");
      remove("user");
      remove("organization");
    },
  });

  useEffect(() => {
    if (!user) {
      navigate("/");
    }
    if (user && user.authenticated) {
      if (user.owner) {
        navigate("/owner/users");
      } else if (user.administrator) {
        navigate("/administration/users");
      } else {
        navigate("/dashboard");
      }
    }
  }, [user, navigate]);

  const handleLogout = async () => {
    await logout();
    navigate("/", { replace: true });
  };

  return (
    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      backgroundColor="gray.100"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        flexDir="column"
        mb="2"
        justifyContent="center"
        alignItems="center"
      >
        <Avatar bg="blue.500" />
        <Heading color="blue.400">DocuTrail</Heading>
        <Heading size="sm" color="blue.400">
          Please enter your one-time password (OTP)
        </Heading>
        <br />
        <Box minW={{ base: "90%", md: "468px" }}>
          <form>
            <Stack
              spacing={4}
              p="1rem"
              backgroundColor="whiteAlpha.900"
              boxShadow="md"
            >
              <FormControl>
                <InputGroup justifyContent="center">
                  <HStack spacing="2">
                    <PinInput
                      autoFocus={true}
                      isDisabled={validatingOTP}
                      otp
                      colorScheme="linkedin"
                      type="number"
                      onComplete={(otp) => {
                        setValidatingOTP(true);
                        login({ variables: { input: { otp } } });
                      }}
                      size="lg"
                    >
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                    </PinInput>
                  </HStack>
                </InputGroup>
              </FormControl>
              <Button
                onClick={handleLogout}
                borderRadius={0}
                variant="solid"
                width="full"
              >
                <Text color="blue.500">Request New OTP</Text>
              </Button>
            </Stack>
          </form>
        </Box>
      </Stack>
      <Footer 
        bottom="0"
        position={{ md: "fixed", base: "absolute"}}
      />
    </Flex>
  );
};

export default Login;
