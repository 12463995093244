import { useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";

const useToastSet = () => {
  const toast = useToast();
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [infoMessage, setInfoMessage] = useState("");

  useEffect(() => {
    if (successMessage) {
      toast({
        title: "Success",
        description: successMessage,
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "bottom-left",
      });
      setSuccessMessage('')
    }
  }, [successMessage, toast, setSuccessMessage]);

  useEffect(() => {
    if (errorMessage) {
      toast({
        title: "Error",
        description: errorMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom-left",
      });
      setErrorMessage('')
    }
  }, [errorMessage, toast, setErrorMessage]);

  useEffect(() => {
    if (infoMessage) {
      toast({
        title: "Info",
        description: infoMessage,
        status: "info",
        duration: 5000,
        isClosable: true,
        position: "bottom-left",
      });
      setInfoMessage('')
    }
  }, [infoMessage, setInfoMessage, toast]);

  return {
    setInfoMessage,
    setErrorMessage,
    setSuccessMessage
  }
}

export default useToastSet;
